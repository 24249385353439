
.btn-connexion-assurance {
    background: #005D86;
    border-radius: 9px;
    border: 1px solid #005D86;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 0.75px;
    color: #ffffff;
    transition: 0.3s ease-in-out;
    text-transform: uppercase;
}

.btn-connexion-assurance:hover {
    background-color: #fff;
    color: #005D86;
    transition: 0.3s ease-in-out;
    border: 1px solid transparent;
    box-shadow: 0px 2px 9px rgba(0,0,0,0.09);
}

.mdpOublie-assurance {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.75px;
    color: #043B63;
    transition: 0.2s ease;
    border-style: none;
    background: transparent;
}