section.page-garde-container-assurance {
    background: linear-gradient(180deg, #005D86 0%, #0890CC 100%);;
    /* height: 100vh;  */
    width: 100vw;
  }
  .container-padding-bottom-assurance {
      padding-bottom: 6rem;
  }
  .introduction-assurance {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 50px;
    padding: 100px 10px 15px 10px;
    text-transform: uppercase;
  }
  .introduction-assurance span {
    font-size: 25px;
    text-transform: none !important;
  }
  .introduction-slogan-assurance {
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* text-align: center;
    padding: 1% 23%; */
    padding: 10px;
  }
  .introduction-slogan-assurance p {
    color: rgba(255, 255, 255, 0.75);
  }
  .introduction-action-btn-container {
    padding: 20px;
  }
  .easypm-in-macbook-image-container-assurance {
    width: 100%;
    padding-top: 145px;
}
.easypm-in-macbook-image-content-assurance {
  text-align: center;
}
img.easypm-in-macbook-image-assurance {
    width: 100%;
}
.why-easypm-section-container-assurance {
    padding-top: 9%;
    background: #ffffff;
    padding-bottom: 9%;
}
.hp-section-title-assurance {
  text-align: center;
  color: #01223A;
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 65px;
  font-style: normal;
}
.why-easypm-item-icon-container-assurance {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-bottom: 25px;
  background: #005D86;
}
.why-easypm-icon-assurance {
  width: 65px;
  height: 65px;
}
.why-easypm-item-textual-assurance p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    text-align: justify;
  }
  .functionnalities-section-container-assurance {
    padding-top: 10%;
    background: #FFFFFF;
    padding-bottom: 15%;
  }
  .functionnalities-row-item-assurance {
    margin-bottom: 70px;
  }
  .functionnalities-col-item-assurance {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .secondy h2.sous-section-title-assurance {
    text-align: right;
  }
  .functionnalities-content-image-assurance.easypm-app-mobile-image-container-assurance {
    text-align: center;
  }
  .functionnalities-image-item-assurance {
    width: 100%;
  }
  img.functionnalities-image-item-assurance.easypm-app-mobile-image-assurance {
    width: 55%;
  }
  .contact-light-container-assurance {
    padding: 100px 30px;
    background: #005D86;
    position: relative;
  }
  .contact-light-col-assurance {
    text-align: center;
  }
  .contact-light-col-assurance.textual-contact-col-assurance {
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 24px;
  }
  .contact-light-btn-assurance {
    background: #FFFFFF;
    width: 45%;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    padding: 16px;
  }
  .contact-form-col-assurance {
    margin-top: 25px;
  }
  .functionnalities-content-assurance p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.7);
  }
  .functionnalities-content-assurance {
    padding-right: 45px;
  }
  .secondy .functionnalities-content-assurance {
    padding-right: 0;
    padding-left: 45px;
  }