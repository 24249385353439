#chartdiv {
    width: 100%;
    height: 200px;
  }
  #content {
    
    padding: 4px;
    /* width: 562px; */
  }
  #page-2 td {
      background-color: #F7F7F7;
      border: none;
  }
  .text-cout {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 26px;
    text-transform: uppercase;
    color: #000000;
  }
  .p-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #000000;
  }
  .img-facture {
      width: 125px;
  }
  .page {
    flex-direction: row;
    display: flex;
    align-items: center;
    
  }
  .section {
    margin: 10px;
    padding: 10px;
    flex-grow: 1px;
  }
  .blocright {
      flex-direction: row;
      display: flex;
  }
  .bgblue {
      background: #043B63;
      padding: 6px;
      margin: 1px;
  }
  .bggreen {
      background: #02A69C;
      padding: 6px;
      margin: 1px;
  }
  .textfacturebloc {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      color: #FFFFFF;
  }
  .textlibellebloc {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #FFFFFF;
            
  }
  .sectiondeux {
      display: flex;
      padding-bottom: 20px;
      padding-top: 20px;
  }
  .bloctrois {
      background: #043B63;
  }
  .textbloctrois {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600px;
      font-size: 11px;
      color: #FFFFFF;
  }
  .list {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500px;
      font-size: 10px;
      color: #043B63;
    
  }
  .spanli {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      color: #000000;
      padding-left: 15px;
  }

  .text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      color: #000000;
  }
  .table {
      padding-top: 10px;
      padding-bottom: 80px;
  }
  .entete {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      color: #000000;
            
  }
  .listgray {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600px;
      font-size: 10px;
      color: #000000;
      background: #DCE5EC;
      margin-top: 2px;
  }
  .textentete {
      color: #fff
  }
  .listred {
      background: #CAF2F0;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      color: #000000;
      margin-top: 6px;
  }
  .ul {
      padding-left: 10px;
  }
  .chart {
      padding-top: 20px;
  }