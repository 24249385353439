.component-profil-user {
    background-color: #cecece;
}
.nom-profil-user {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.025em;
    color: #000000;
}
.text-profil-user {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.025em;
    color: #000000;
    text-align: center;
}
.label-profil-user {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.025em;
    color: #356FC2;
}
.icon-edit-profil {
    position: absolute;
    top: 50%;
    right: 6%;
}
.img-profil-app {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}