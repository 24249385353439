.btn-rdv-beneficiaire {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #252733;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  padding: 1rem;
}
.btn-rdv-beneficiaire:hover {
  color: #252733;
  text-decoration: none;
}
.card-item-rdv {
  /* padding: 2rem; */
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.bloc-entete-card-item-rdv {
  background: #0577a9;
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.text-card-item-rdv {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
}
.titre-auteur-rdv {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.titre-auteur-rdv:hover {
  text-decoration: none;
  color: #000;
}
.text-auteur-rdv {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
.text-localisation-rdv {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.entete-modal-agenda {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 43px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}
.bloc-date-modal-agenda {
  background: #ffffff;
  box-shadow: 0px 2px 43px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.label-form-agenda-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.input-form-agenda-modal {
  background: transparent;
  border: none;
}
.input-form-agenda-modal-white {
  background: #ffffff;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #cccccc;
  height: 40px;
}
.time-icon-modal {
  width: 14.17px;
  height: 16.67px;
}

.date-courante-agenda {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
}
.chiffre-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: rgba(0, 0, 0, 0.7);
}
.input-event-agenda {
  border-bottom: 2px solid #d6d6d6;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #cccccc;
}
.label-form-add-event {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 6px;
}
.input-add-event {
  background: #ffffff;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 16px;
  color: #71717a;
  border: none;
}
.input-group-text {
  background: #fff;
  border: none;
}
.input-group {
  background: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}
.textarrea-event {
  background: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  padding: 18px;
}
.container-checkbox {
  padding: 15px 0;
  border-radius: 10px;
  display: flex;
  gap: 35px;
}
.container-checkbox label.checked span {
  background: rgba(255, 0, 0, 0.616) !important;
  color: #fff;
}
.checkbox-add-event input {
  cursor: pointer;
  user-select: none;
  display: block;
}
.checkbox-add-event label span {
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  transition: 0.4s;
  padding: 0 35px;
  height: 46px;
  color: #414141;
  font-weight: bold;
  display: flex;
  align-items: center;
  column-gap: 7px;
  background: #cccccc;
  border-radius: 20px;
}
.checkbox-add-event label span .icon {
  font-size: 1.1em;
}
.checkbox-add-event label input {
  pointer-events: none;
  display: none;
}
.checkbox-add-event label input:checked + span {
  border-color: #02a69c;
  color: #fff;
  background: #02a69c;
  border-radius: 20px;
}
input[type=date]::-webkit-clear-button, /* blue cross */
input[type=date]::-webkit-inner-spin-button, /* up */
input[type=date]::-webkit-outer-spin-button, /* down */
input[type=date]::-webkit-calendar-picker-indicator /* datepicker*/ {
  display: none;
}
.crud-submit-btn-beneficiaire {
  background: #005d86;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
.btn:focus {
  box-shadow: none;
}
.select-filere-filter-module {
  background: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.08);
  border-radius: 5px !important;
  background: url(../../../../images/icons/down.png) 90% 47% / 13px no-repeat
    #f7f7f7 !important;
  appearance: none;
  border: #f7f7f7 !important;
}
.label-filter-rdv {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}
.image-pp-card-detail-rdv {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.bloc-info-detail-praticien {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.texte-info-normal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding: 8px 0;
}
.texte-info-bold {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding: 8px 0;
}
.crud-submit-btn-beneficiaire:hover {
  background: #fff;
  color: #005d86;
  border: 1px solid #005d86;
}
.bgColor {
  background: #005d86;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}
.carousel.carousel-slider .control-arrow {
  top: -972px !important;
  color: #000 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}
.carousel .carousel-status {
  display: none;
}
.carousel .control-dots {
  display: none;
}
.carousel .slide .legend {
  color: #000;
  position: inherit !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  opacity: inherit !important;
  color: rgba(0, 0, 0, 0.8) !important;
  margin-left: inherit !important;
  background: transparent;
  bottom: inherit !important;
  left: inherit !important;
}
.text-legend-bold {
  font-weight: bold;
}
.position-content-carousel {
  position: absolute;
  top: 100%;
}
.carousel .slider-wrapper {
  height: 1000px;
}
.container-checkbox-carousel {
  padding: 15px 0;
  border-radius: 10px;
  display: flex;
  grid-gap: 35px;
  gap: 10px;
  flex-direction: column;
}
.checkbox-carousel label {
  cursor: pointer;
  user-select: none;
  display: block;
}
.checkbox-carousel label span {
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  transition: 0.4s;
  padding: 0 35px;
  height: 46px;
  color: #414141;
  display: flex;
  align-items: center;
  column-gap: 7px;
  background: #cccccc;
  border-radius: 20px;
}
.checkbox-carousel label span .icon {
  font-size: 1.1em;
}
.checkbox-carousel label input {
  pointer-events: none;
  display: none;
}
.checkbox-carousel label input:checked + span {
  border-color: #02a69c;
  color: #fff;
  background: #02a69c;
  border-radius: 20px;
}
.libelle-calendar-carousel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.7);
}
.list-calendar-carousel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.container-easypm {
  padding-left: 6rem;
  padding-right: 6rem;
  margin-top: 3rem;
}
.form-control:focus {
    border-color: inherit !important;
    box-shadow: none;
}