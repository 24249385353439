.crud-submit-btn-assurance {
    background: #01223A;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 100%;
    padding: 1rem;
}
.crud-submit-btn-assurance:hover {
    color: #01223A;
    border: 1px solid #01223A;
    background-color: #fff;
    text-decoration: none;
}
.cancelleb-btn-assurance {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.045em;
    color: #515151;
    background-color: transparent;
    width: 100%;
    padding: 1rem;

}
.text-revoi-password {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #0C0B0B;
}
.btn-assurance-retour {
    background: #01223A;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    color: #FFFFFF;
    height: 40px;
}
.btn-assurance-retour:hover {
    color: #FFFFFF;
    text-decoration: none;
}
.input-group-text {
    background-color: transparent !important;
    border: none !important;
}
.input-group-percent {
    position: absolute;
    right: 4%;
    top: 8px;
}