
.bloc-entete-card-item-rdv-prestataire {
    background: #02A69C;
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
}
.text-motif-consultation-prestataire {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #02A69C;

}
.courant-date-rdv {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);

}
.-today {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #F42D2D !important;
    background: rgba(244, 45, 45, 0.2);
    border-radius: 50%
}