.bloc-base-de-donnee {
    background: #FAFAFA;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
.no-link:hover {
    text-decoration: none;
}
.btn-prendre-rv {
    background: #E5F3F3 !important;
    border-radius: 5px !important;
}
.link-ajout {
    color: #0C0B0B !important;
}
.link-ajout:hover {
    text-decoration: none;
}
.ck-content p {
    color: #000000;
  }
  
  .dzu-dropzone {
    background: #f3f3f3;
    border: 1px solid #959595 !important;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
    border-radius: 10px !important;
    padding-top: 59px;
  }

  .label-img-container {
    width: 24px;
    height: 24px;
  }
  .dzu-dropzone {
    background: #f3f3f3;
    border: 1px solid #959595 !important;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px !important;
  }
  .dzu-dropzone::-webkit-scrollbar {
    display: none;
  }
  .editor-text img {
    width: 36% !important;
  }  
  .editorClassName {
    min-height: 300px;
  }
  .text-details-info-utile {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 194.7%;
    color: rgba(0, 0, 0, 0.7);
    text-align: justify;
  }
  .titre-details-info-utile {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 77px;
    color: rgba(0, 0, 0, 0.8);
    text-align: justify;
  }

  .date-details-info-utile {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.5);
  }