.nav-link-vertival-custom-v2 {
    padding: 0px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    transition: .2s ease-in-out;
}

.nav-link-vertival-custom-v2>.rs-nav-item-content {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19px;
}

.nav-link-vertival-custom-v2>.rs-nav-item-content:hover {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}

.nav-link-vertival-custom-v2>.rs-nav-item-content:focus {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}

.icon-navbar-vertical-v2 {
    width: 20px;
    margin-right: 15px;
    position: absolute;
    left: 20px;
    top: 12px;
}

.nav-link-vertival-custom-v2.rs-nav-item-active {
    background: #f39200 !important;
}

.nav-link-vertival-custom-v2>.rs-nav-item-content {
    color: #ffffffbe !important;
}

.nav-link-vertival-custom-v2>.rs-dropdown-toggle {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19px;
}

.nav-link-vertival-custom-v2>.rs-dropdown-toggle {
    color: #ffffffbe !important;
}

.nav-link-vertival-custom-v2>.rs-dropdown-toggle:hover {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}

.nav-link-vertival-custom-v2>.rs-dropdown-toggle:focus {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}

.nav-link-vertival-custom-v2>ul {
    background-color: #ffffff1c !important;
}

.nav-link-vertival-custom-v2>.rs-dropdown-item-content {
    color: #ffffffbe !important;
    padding: 10px 20px 10px 35px !important;
}

.nav-link-vertival-custom-v2>.rs-dropdown-item-content:hover {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}

.icon-navbar-vertical-v2-sous-menu {
    width: 18px;
    margin-right: 15px;
}

.navbar-vertical-customisation-v2 {
    overflow: scroll;
    padding: 40px 0 150px 0;
    min-height: 300px;
    max-width: 17%;
    width: fit-content;
    background-color: #01223A;
    position: fixed;
    left: 0;
    right: 0;
    top: 70px;
    z-index: 999;
    height: 100%;
}

.navbar-vertical-customisation-v2-assurance {
    overflow: scroll;
    padding: 40px 0 150px 0;
    min-height: 300px;
    max-width: 17%;
    width: fit-content;
    background-color: #005D86;
    position: fixed;
    left: 0;
    right: 0;
    top: 70px;
    z-index: 999;
    height: 100%;
}

.navbar-vertical-customisation-v2::-webkit-scrollbar {
    width: 0px;
}

.navbar-vertical-customisation-v2::-webkit-scrollbar-track {
    background: transparent;
}

.navbar-vertical-customisation-v2::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 3px solid transparent;
}
.notif-reservetion {
    background: #F45B5B;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}