.img-patient {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.text-patient-reservation {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #393939;
}
.btn-accepter-reservation {
    background: #005D86;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: .5rem;
}
.btn-reaffecter-reservation {
    background: #02A69C;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: .5rem;
}
.btn-Rejeter-reservation {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #F45B5B;
    padding: .5rem;
}
.height-calendar {
    height: 500px;
}
.text-confirm-reservation {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #02A69C;

}
.para-text-confirm-modal {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #404040;
}
.btn-confirm-reservation-modal {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #02A69C;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 1rem;
}
.modal-header {
    border-bottom: none;
}
.titre-list-modal {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #393939;
}
.list-reaffecter-modal {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
}