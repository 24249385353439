.title-fiche {
  border-radius: 15px;
  border: 2px solid #001028;
  padding: 1px;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  margin-top: -3%;
  height: 10%;
  width: 100%;
  text-align: center;
  /* border: 2px solid;
    margin-right: 15%;
    margin-left: 15%; */
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin-bottom: 1rem;
}

.sub-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
}

/* .sub-title .label-title {
    text-decoration: underline;
} */

.label-title {
    text-decoration: underline;
}

tr.col-tr {
    border: 1px solid;
}

th.col-th {
    border: 1px solid;
}

td.col-td {
    border: 1px solid;
}

.totalText {
    padding-left: 15% !important;
    border: 1px solid;
}

.visa-col {
    font-size: 20px;
}

.visa-left {
    margin-left: 20px;
}

.visa-right {
    margin-right: 20px;
    text-align: end;
}