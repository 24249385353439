.title-facture {
  border-radius: 15px;
  border: 1px solid grey;
  align-items: center;
  justify-content: center;
  margin-top: -3%;
  height: 10%;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin-bottom: 1rem;

  width: 50%;
  /* text-align: center; */
  /* justify-content: center; */
  padding: 10px;
  margin-left: 25%;
  background: whitesmoke;
}

.sub-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
}

/* .sub-title .label-title {
      text-decoration: underline;
  } */

.label-title {
  text-decoration: underline;
}

tr.col-tr {
  border: 1px solid;
}

th.col-th {
  border: 1px solid;
}

td.col-td {
  border: 1px solid;
}

.totalText {
  padding-left: 15% !important;
  border: 1px solid;
}

.visa-col {
  font-size: 20px;
}

.visa-left {
  margin-left: 20px;
}

.visa-right {
  margin-right: 20px;
  text-align: end;
}

.table-facture {
  border-style: dotted !important;
}

th.col-fact {
  border-style: dotted !important;
}

table .col-fact {
  padding: 0.3rem !important;
  font-weight: 400 !important;
}

.table-facture1 {
  /* border-style: dotted !important; */
  border: 2px solid;
}

th.col-th.col-fact1 {
  border-bottom: 2px solid;
}

th.col-fact1 {
  /* border-style: dotted !important; */
  border: 2px solid;
}

table .col-fact1 {
  padding: 0.3rem !important;
  font-weight: 400 !important;
}

td.col-td.col-fact1 {
  border: 2px solid;
}

.recap-header {
  border: 1px solid;
  background-color: whitesmoke;
}

.recap-th {
  border: 1px solid;
}
