.faq-beneficiaire {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 1rem;
}
.detail-aide {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #005D86;

}
.date-publication-aide {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.8);

}
.nombre-vus {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #5E5B5B;
    text-align: right;
}