.btn-generer-rapport {
    background-color: transparent;
}
.breadcrumbs-rapports {
    margin-top: 5%;
    background: #fff;
    padding-left: 9%;
}
.breadcrumb {
    background-color: transparent !important;
}
.breadcrumb .nav-item-breadcrumb {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7) !important;
}
.breadcrumb .nav-item-breadcrumb:active {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7) !important;
    padding-left: 10px;
}
.breadcrumbs-rapports-filter {
    margin-top: 1%;
    background: #fff;
    padding-top: 23px;
    padding-bottom: 14px;
    margin-left: 8.5%;
    margin-right: -0.2rem;
}
.text-generer-rapport-assurance {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
}
.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: rgba(0, 0, 0, 0.7);
    content: ">";
}
.table-actions-btn-container-assurance {
    display: flex;
    align-items: center;
}
#gestion-rapport-assureur .card-rapports-assureur {
    height: 550px;
}
#gestion-rapport-assureur.table-bordered tbody tr td {
    padding: 1rem !important;
}
.select-date-assureur {
    background: #F7F7F7;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 17px;
    color: #252733;
    border: none;
    /* appearance: inherit; */
    padding: 10px 20px;
    background: url(/src/images/others/select.png)0% 47% / 13px no-repeat #F7F7F7;
}