.message-entete {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nom-contact {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.text-message-contact {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}
.text-message-contact:hover {
    text-decoration: none;
    color: #000;
}
.img-message-contact {
    width: 60px;
    height: 60px;
}

.bloc-message-envoyer {
    background: #005D86;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.12);
    border-radius: 40px 40px 0px 40px;
    padding: 2rem;
    
}
.text-message-contact-recu {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}
.bloc-message-recu {
    background: #FFFFFF;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.12);
    border-radius: 40px 40px 0px 40px;
    padding: 2rem;
    
}
.text-message-contact-envoyer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    color: #FFFFFF;
}
.title-nom-destinateaire {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}
.input-chat {
    background: #ffffff;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    height: 70px;
    padding: 1rem;
  }
  .input-message {
    border: none;
  }
  .icon-emoticon {
    position: absolute;
    top: 34%;
    left: 11px;
  }
  .icon-attach-file {
    position: absolute;
    top: 31%;
    left: 51px;
  }
  .icon-send-form {
    position: absolute;
    top: 33%;
    right: 2%;
  }
  .padd-input-message {
      padding-left: 5rem;
  }