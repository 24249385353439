.nav-link-vertival-custom-v2{
    padding: 0px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    transition: .2s ease-in-out;
}
.nav-link-vertival-custom-v2>.rs-nav-item-content{
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19px;
}
.nav-link-vertival-custom-v2>.rs-nav-item-content:hover {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}
.nav-link-vertival-custom-v2>.rs-nav-item-content:focus {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}
.icon-navbar-vertical-v2 {
    width: 20px;
    margin-right: 15px;
    position: absolute;
    left: 20px;
    top: 12px;
}
.nav-link-vertival-custom-v2.rs-nav-item-active{
    background: #f39200 !important;
}
.nav-link-vertival-custom-v2>.rs-nav-item-content{
    color: #ffffffbe !important;
}
.nav-link-vertival-custom-v2>.rs-dropdown-toggle{
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19px;
}
.nav-link-vertival-custom-v2>.rs-dropdown-toggle{
    color: #ffffffbe !important;
}
.nav-link-vertival-custom-v2>.rs-dropdown-toggle:hover {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}
.nav-link-vertival-custom-v2>.rs-dropdown-toggle:focus {
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}
.nav-link-vertival-custom-v2>ul{
    background-color: #ffffff1c !important;
}
.nav-link-vertival-custom-v2>.rs-dropdown-item-content{
    color: #ffffffbe !important;
    padding: 10px 20px 10px 35px !important;
}
.nav-link-vertival-custom-v2>.rs-dropdown-item-content:hover{
    background: #f39200 !important;
    color: #fff !important;
    transition: 0.2s ease;
}
.nav-assurance-color .navbar-vertical-customisation-v2 {
    padding: 40px 0 150px 0;
    min-height: 300px;
    max-width: 17%;
    width: fit-content;
    background-color: #005D86 ;
    position: fixed;
    left: 0;
    right: 0;
    top: 70px;
    z-index: 999;
    height: 100%;
}
#MobileMenuDivWrapper { 
    height: 200px;
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling:touch; 
  }