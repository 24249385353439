.tab-parametre-assurance {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.tab-parametre-assurance.active {
    color: #000 !important;
    font-weight: bold;
    border-bottom: 2px solid #01223A !important;

}
.nav-tabs .nav-link.active {
    border-color: inherit !important;
}
.nav-tabs {
    border-bottom: inherit !important;
}
.nav-tabs .nav-link {
    border: inherit !important;
}
.nav-tabs .nav-link.active {
    border-bottom: 2px solid #01223A !important;

}
.signature-visa-assurance {
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    padding: 20px;
}
.logo-assureur {
    background: #C4C4C4;
    width: 250px;
    height: 250px;
    border-radius: 50%;
}
.no-link:hover {
    text-decoration: none;
}