.contact-form-col {
    margin-top: 25px;
}
.contact-form-title {
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
}
.contact-form-row label {
    color: #fff;
    font-weight: 500;
}
.contact-form-row input, 
.contact-form-row textarea {
    color: #000 !important;
    border-color: #fff !important;
}
.contact-form-row input ::placeholder {
    color: #ffffff54 !important;
}

.hiddenable-form{
    animation: bouncingFromLeft ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:forwards; /*when the spec is finished*/
    -webkit-animation: bouncingFromLeft ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: bouncingFromLeft ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: bouncingFromLeft ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: bouncingFromLeft ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
  }

  
  @keyframes bouncingFromLeft{
    0% {
      opacity:0;
      transform:  translate(-1500px,0px)  ;
    }
    60% {
      opacity:1;
      transform:  translate(30px,0px)  ;
    }
    80% {
      transform:  translate(-10px,0px)  ;
    }
    100% {
      opacity:1;
      transform:  translate(0px,0px)  ;
    }
  }
  
  @-moz-keyframes bouncingFromLeft{
    0% {
      opacity:0;
      -moz-transform:  translate(-1500px,0px)  ;
    }
    60% {
      opacity:1;
      -moz-transform:  translate(30px,0px)  ;
    }
    80% {
      -moz-transform:  translate(-10px,0px)  ;
    }
    100% {
      opacity:1;
      -moz-transform:  translate(0px,0px)  ;
    }
  }
  
  @-webkit-keyframes bouncingFromLeft {
    0% {
      opacity:0;
      -webkit-transform:  translate(-1500px,0px)  ;
    }
    60% {
      opacity:1;
      -webkit-transform:  translate(30px,0px)  ;
    }
    80% {
      -webkit-transform:  translate(-10px,0px)  ;
    }
    100% {
      opacity:1;
      -webkit-transform:  translate(0px,0px)  ;
    }
  }
  
  @-o-keyframes bouncingFromLeft {
    0% {
      opacity:0;
      -o-transform:  translate(-1500px,0px)  ;
    }
    60% {
      opacity:1;
      -o-transform:  translate(30px,0px)  ;
    }
    80% {
      -o-transform:  translate(-10px,0px)  ;
    }
    100% {
      opacity:1;
      -o-transform:  translate(0px,0px)  ;
    }
  }
  
  @-ms-keyframes bouncingFromLeft {
    0% {
      opacity:0;
      -ms-transform:  translate(-1500px,0px)  ;
    }
    60% {
      opacity:1;
      -ms-transform:  translate(30px,0px)  ;
    }
    80% {
      -ms-transform:  translate(-10px,0px)  ;
    }
    100% {
      opacity:1;
      -ms-transform:  translate(0px,0px)  ;
    }
  }