.text-assurance-edit-soin {
    justify-content: space-between;
}
.bg-text-assurance-edit {
    background: #F1F1F1
}
.text-edit-assurance {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #0C0B0B;
}