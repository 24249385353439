section.page-garde-container-first-landingpage {
    background-image: url(../../../images/landingpage/banniere.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100vh; */
    width: 100vw;
    /* position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px; */
  }
  .introduction-first-landingpage {
    color: #ffffff;
    font-weight: bold;
    font-size: 50px;
    padding: 100px 10px 15px 10px;
    text-transform: uppercase;
    text-align: center;
  }
  .introduction-first-landingpage span {
    font-size: 25px;
    text-transform: none !important;
  }
  .introduction-slogan-first-landingpage {
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    padding: 1% 23%;
    /* padding: 10px; */
  }
  .introduction-slogan-first-landingpage p {
    color: rgba(255, 255, 255, 0.75);
  }
  .text-i-am {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
  }
  .i-am {
    background: #FFFFFF;
    border-radius: 16px;
  }
  .i-am-padding-bottom {
      margin-bottom: 350px;
  }
  .easypm-in-macbook-image-container-first-landingpage {
      width: 100%;
      z-index: 99999;
  }
  .easypm-in-macbook-image-first-landingpage {
      width: 48%;
  }
  .easypm-in-macbook-image-content-first-landingpage {
    text-align: center;
    position: absolute;
    top: 31%;
    width: 100%;
    z-index: 99999;
}
.margin-bottom-contact {
    margin-top: 434px;
}
.contact-light-container-first-landingpage {
    padding: 100px 30px;
    background: #C4C4C4;
    position: relative;
}
.contact-light-container-first-landingpage:after {
    content: "";
    width: 32%;
    height: 100%;
    position: absolute;
    background: rgba(240, 240, 240, 0.05);
    top: 20%;
  }
  .contact-light-container-first-landingpage:before {
    content: "";
    width: 10%;
    height: 50%;
    position: absolute;
    background: rgba(240, 240, 240, 0.05);
    top: 50%;
    right: 40%;
  }
  .ldp-footer-item-title-blue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 174.4%;
    color: #005D86;
  }